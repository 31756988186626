import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入封装的ajax
import Http from './service/http'
// 引用px转换rem
// import './common/rem.js'
// 亚马逊sdk
// import './common/ymxSdk.js'
// 引入全局封装的js
import global from './common/global.js'
// 引入全局css reset重置,
import './assets/styles/reset.css'
import './assets/styles/animate.min.css'
import './assets/styles/iconfont/iconfont.js'


/*****国际手机区号****/
import VuCountryIntl from 'vue-country-intl';
// import css
import 'vue-country-intl/lib/vue-country-intl.css'
// Global registration component
Vue.component(VuCountryIntl.name, VuCountryIntl);


// 引入vant组件库
import {
  Button,
  Popup,
  Overlay,
  Icon,
  Collapse,
  CollapseItem,
  Cell,
  Divider,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Dialog,
  ActionSheet,
  Picker,
  Search,
  DropdownMenu,
  DropdownItem,
  Uploader,
  Tab,
  Tabs,
  ImagePreview,
  DatetimePicker
} from 'vant'
Vue.use(Popup)
  .use(Button)
  .use(Overlay)
  .use(Icon)
  .use(Collapse)
  .use(CollapseItem)
  .use(Cell)
  .use(Divider)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(RadioGroup)
  .use(Radio)
  .use(Dialog)
  .use(ActionSheet)
  .use(Picker)
  .use(Search)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Uploader)
  .use(Tab)
  .use(Tabs)
  .use(ImagePreview)
  .use(DatetimePicker)


// 路由守卫
router.beforeEach((to, from, next) => {
  // 删除上个页面正在请求的接口
  if (Http.axiosPromiseArr.length > 0) {
    Http.axiosPromiseArr.forEach((ele, index) => {
      ele.cancel()
      delete Http.axiosPromiseArr[index]
    })
  }
  // 路由拦截
  // 判断该路由是否需要登录权限
  if (to.meta.isLogin) {
    if (store.state.token) {
      next()
    } else {
      // 未登录,跳转到登陆页面
      next({
        path: '/login?type=1'
      })
    }
  } else {
    next()
  }
})

Vue.config.productionTip = false
Vue.prototype.$Http = Http.Http
Vue.prototype.app = global
Vue.prototype.$bg = '#1ABDC3'

window.addEventListener('scroll', function () {
  var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  if (scrollTop > 50) {
    store.state.header = true
  } else {
    store.state.header = false
  }
}, true)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
