import router from '../router'
import { Toast } from 'vant'

// 返回方法,没有历史记录就返回首页
function back () {
  let isback = false
  if (window.history.length <= 1) {
    isback = true
    router.push({ path: '/' })
    return
  } else {
    isback = true
    router.go(-1)
  }
  if (isback === false) {
    setTimeout(() => {
      router.push({ path: '/' })
    }, 500)
  }
}
// 跳转页面
function goView(param, replace) {
  if (typeof param == 'string') {
    param = {
      path: param
    }
  }
  if (replace) {		// 判断是不是重定向
     router.replace(param)
     return
   }
  if (param.path) {
  	if(!param.query){
  		param.query = {}
  	}
	// param.query = {
	// 	...param.query,
	// 	// 这里是添加公共参数，每次跳转的时候都会进行附带上去
	//     name: "Feng"
  // }
    router.push({
      path: param.path,
      query: param.query
    })
  } else if (param.name) {
    router.push({
      name: param.name,
      params: param.params
    })
  } else if (param.outlink) {		// 附加的跳转方式。（可以根据自己需求来进行相对应的判断）
    console.log("开始外部跳转")
  } else {
    console.log("path's value and name's value in param is null")
  }
}

function toast(e){
  Toast(e);
}

// 支付方法
function pay(payInfo, type) {
  // 1支付宝，2微信，3银联
  if(type == 'alipay') {
    console.log('支付宝支付')
    debugger
    let divForm = document.getElementsByTagName('divform')
    if (divForm.length) {
      document.body.removeChild(divForm[0])
    }
    const div=document.createElement('divform');
    div.innerHTML=payInfo; // data就是接口返回的form 表单字符串
    div.style.display='none' // 隐藏表单
    document.body.appendChild(div);
    document.forms[0].submit();
  }else if(type == 'wxpay') {
    let jweixin = require('./jweixin-module.js')
    console.log('微信支付')
    console.log(jweixin)
    location.href = payInfo.mwebUrl;
    jweixin.config({
      beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: payInfo.appId, // 必填，企业微信的corpID
      timestamp: Number(payInfo.timestamp), // 必填，生成签名的时间戳
      nonceStr: payInfo.nonceStr, // 必填，生成签名的随机串
      signature: payInfo.corpSignature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
      jsApiList: ["chooseWXPay"] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    });
    jweixin.ready(function () {
      jweixin.chooseWXPay({
        appId: payInfo.appId,
        timestamp: Number(payInfo.timestamp), // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: payInfo.nonceStr, // 支付签名随机串，不长于 32 位
        package: payInfo.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
        signType: payInfo.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: payInfo.paySign, // 支付签名
        success: function(res) {
          // 支付成功后的回调函数
          if (res.errMsg == "chooseWXPay:ok") {
            //支付成功
            alert('支付成功');
          } else {
            alert(res.errMsg);
          }
        },
        cancel: function(res) {
          alert('支付取消');
        }
      });
      jweixin.error(function(res){
      	alert(JSON.stringify(res));
      });
    });		
  }else if(type == 3) {
    console.log('银联支付')
  }
}

export default {
  back,
  goView,
  toast,
  pay
}
