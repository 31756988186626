import axios from 'axios'
import router from '../router'
import service from './contactApi'
import { Toast } from 'vant'
import store from '../store'

// service 循环遍历输出不容的请求方法
const instance = axios.create({
  // baseURL: '/api',
  baseURL: 'http://h.chasel.top/api',
  timeout: 60000
})

const Http = {} // 请求方法的容器

// 请求格式统一
for (const key in service) {
  const api = service[key]
  // async 作用：避免进入回调地狱
  Http[key] = async function ( // 参数
    params, // 请求参数
    isFormData = false, // 是否是form-data请求
    config = {} // 请求配置参数
  ) {
    let newParams = {} // 为转换form-data格式

    // content-type 是否试form-data的判断
    if (params && isFormData) {
      newParams = new FormData()
      for (const i in params) {
        newParams.append(i, params[i])
      }
    } else {
      newParams = params
    }



    // 不同请求判断
    let response = {}// 请求返回值
    if (api.method === 'post' || api.method === 'put' || api.method === 'patch') {
      try {
        response = await instance[api.method](api.url, newParams, config)
      } catch (err) {
        response = err
      }
    } else if (api.method === 'get' || api.method === 'delete') {
      config.params = newParams
      try {
        response = await instance[api.method](api.url, config)
      } catch (err) {
        response = err
      }
    }
    return response // 返回响应值
  }
}

const axiosPromiseArr = []
// 请求拦截器
instance.interceptors.request.use(
  config => {
    let token = store.state.token
    if (token) {
      config.headers.token = token;    //将token放到请求头发送给服务器
    }
    config.cancelToken = new axios.CancelToken(cancel => {
      axiosPromiseArr.push({ cancel })
    })
    // // 请求等待lodding 遮罩层
    // store.commit('loaddingShow', true)
    return config
  }, () => {
    // 清除loadding
    store.commit('loaddingShow', false)
    Toast('Request error')
  }
)

// 响应拦截器
instance.interceptors.response.use(
  res => {
    
    if(res.data.code && res.data.code != 200){
      // 清除loadding
      store.commit('loaddingShow', false)
      if(res.data.code == -1) {
        store.state.token = ''
        store.state.userInfo = ''
        router.push('login')
      }else{
        Toast(res.data.message)
      }
    }
    return res.data
  }, (error) => {
    // 清除loadding
    // store.commit('loaddingShow', false)
    // 取消请求的情况下，终端Promise调用链（判断是取消还是响应错误）
    if (axios.isCancel(error)) {
      console.log('取消请求成功')
      // 清除loadding
      // store.commit('loaddingShow', false)
      return new Promise(() => {})
    } else {
      Toast('Request error')
      // 清除loadding
      store.commit('loaddingShow', false)
      return Promise.reject(error)
    }
  }
)

export default {
  Http,
  axiosPromiseArr
}
