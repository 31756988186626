// 定义默认值

// 是否显示全局loadding
let loadding = false
// token
let token = ''
// 用户信息
let userInfo = ''
// 头部显示导航条
let header = false
// 选择后的商品详情
let proDetail = ''
// 购物车数量
let cartNum = 0
// 收藏数量
let collectNum = 0
// 历史搜索
let history = []
// 眼睛方案
let eysType = ''
// 眼睛颜色
let eyeColor = ''
// 眼睛厚度
let eyeLens = ''
// 分类信息
let categoryData = ''
// 游客地址
let visitorsAddr = ''

var store = localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')) : {
  loadding,
  token,
  userInfo,
  header,
  proDetail,
  cartNum,
  collectNum,
  history,
  eysType,
  eyeColor,
  eyeLens,
  categoryData,
  visitorsAddr
}

export default store
