export default {
  // 全局loadding
  loaddingShow (state, type) {
    state.loadding = type
  },
  // 用户token
  setToken (state, value) {
    state.token = value
  },
  // 用户信息
  setUserInfo (state,value) {
    state.userInfo = value
  },
  // 用户信息
  setProDetail (state,value) {
    state.proDetail = value
  },
  // 购物车数量
  setCartNum (state,value) {
    state.cartNum  = value
  },
  //收藏数量
  setCollectNum (state,value){
    state.collectNum = value
  },
  // 搜索历史记录
  setHistory (state,value) {
    state.history  = value
  },
  // 眼睛类型
  setEyeType (state,value) {
    state.eysType  = value
  },
  // 眼睛color
  setEyeColor (state,value) {
    state.eyeColor  = value
  },
  // 眼睛lens
  setEyeLens (state,value) {
    state.eyeLens  = value
  },
  // 选择的分类
  setCategoryData (state,value) {
    state.categoryData  = value
  },
  // 设置默认地址
  setVisitorsAddr (state,value) {
    state.visitorsAddr  = value
  }
}
