<template>
    <div id="app">
        <!-- 是否刷新 -->
        <template v-if="isRouterAlive">
            <!-- 判断是否缓存 -->
            <keep-alive>
                <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>
        </template>

        <!-- 全局loadding -->
        <div class="mask">
            <van-overlay :show="isShow">
                <div class="app-wrapper" @click.stop>
                    <div class="block">
                        <!-- <img src="./assets/images/loadding.gif" alt=""> -->
                    </div>
                </div>
            </van-overlay>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'App',
        provide() {
            //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
            return {
                reload: this.reload,
            };
        },
        data() {
            return {
                isRouterAlive: true, //控制视图是否显示的变量
            };
        },
        created() {
            // 兼容iOS端微信无法存取sessionStorage的问题
            // 在页面加载时读取localStorage里的状态信息
            if (localStorage.getItem('store')) {
                this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(localStorage.getItem('store'))));
            }
            // 在页面刷新时将vuex里的信息保存到localStorage里
            window.addEventListener('pagehide', () => {
                var store = this.$store.state;
                // 刷新关闭loadding
                store.loadding = false;
                localStorage.setItem('store', JSON.stringify(store));
            });
        },
        computed: {
            // 是否显示loadding
            isShow() {
                return this.$store.getters.isLoadingShow;
            },
        },
        methods: {
            reload() {
                this.isRouterAlive = false; //先关闭，
                this.$nextTick(function () {
                    this.isRouterAlive = true; //再打开
                });
            },
        },
    };
</script>

<style lang="less">
    body {
        font-family: Roboto, Roboto-Regular;
    }

    .mask {
        // loadding层级
        .van-overlay {
            z-index: 9999;
            background-color: rgba(0, 0, 0, 0.7) !important;
        }
        .app-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            z-index: 9999;
            .block {
                width: 120px;
                height: 120px;
                background-color: #ffffff;
                .bdr(4px);
                position: relative;
                box-sizing: border-box;
                img {
                    width: 120px;
                    height: 120px;
                    box-sizing: border-box;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
</style>
